/* IMPORT BOOTSTRAP */
@import url('https://fonts.googleapis.com/css?family=Nunito');

$font-family-base: 'Nunito', sans-serif;

$primary: rgba(37, 166, 82, 1);
$secondary: rgba(220, 222, 223, 1);
$danger: #e83e8c;

// inputs
$input-border-color: $primary;
$input-border-radius: 0;

// buttons
$btn-font-size: 1.0675rem;

@import 'bootstrap/scss/bootstrap.scss';
@import './fa/scss/fontawesome.scss';
@import './fa/scss/regular.scss';
@import './fa/scss/solid.scss';

.plant-background {
  background-image: url('../assets/images/sp-kitchen-background.jpg');
}
